import { compositionFunction as CallToActionComposition } from 'src/components/Common/@electron/CallToAction/composition';
import Composition, { sitecoreMap } from 'src/lib/HOC/Composition';
import { Data } from './data';
import MultiColumn from './index';

// If we can't find a match in sitecoreMap, default to single column layout
const singleColumnDefaultData = sitecoreMap['{C7FCD5F7-A46D-4744-A9CF-44F17A8CEB1B}'];

const { compositionFunction, component } = Composition(MultiColumn)<
  typeof Data & { rendering?: typeof Data }
>(({ fields, rendering, params, ...rest }) => {
  const componentData = sitecoreMap[params?.Layout] || singleColumnDefaultData;

  return {
    ...CallToActionComposition({ fields: fields.datasource, rendering }),
    rendering: { ...fields, ...rendering, ...rest },
    componentData,
  };
});

export { compositionFunction, component as default };
