import { Placeholder } from '@sitecore-jss/sitecore-jss-react';
import CallToAction from 'src/components/Common/@electron/CallToAction';
import { MultiColumnProps } from './types';

const MultiColumn = ({ rendering, componentData, ...rest }: MultiColumnProps) => {
  const exemptComponents = ['PushDownPanel', 'Tab'];
  const getClass = (name: string) => {
    if (componentData.name !== 'single-centered-layout') return '';
    return exemptComponents.includes(name) ? 'w-full' : 'md:max-w-3xl mx-auto';
  };

  return (
    <CallToAction {...rest}>
      <div
        className={
          componentData?.name === 'single-centered-layout' ? 'w-full' : 'md:max-w-3xl mx-auto'
        }
        data-testid={componentData.layoutCssClass}
      >
        <div className="flex flex-wrap md:flex-nowrap w-full">
          <div className={`${componentData.row1CssClass} ${getClass(rendering.componentName)}`}>
            <Placeholder
              name="jss-public-multicolumn-row1"
              rendering={rendering}
              parent="MultiColumn"
            />
          </div>
          <div className={`${componentData.row2CssClass} ${getClass(rendering.componentName)}`}>
            <Placeholder
              name="jss-public-multicolumn-row2"
              rendering={rendering}
              parent="MultiColumn"
            />
          </div>
          <div className={`${componentData.row3CssClass} ${getClass(rendering.componentName)}`}>
            <Placeholder
              name="jss-public-multicolumn-row3"
              rendering={rendering}
              parent="MultiColumn"
            />
          </div>
        </div>
      </div>
    </CallToAction>
  );
};

export default MultiColumn;
